<template>
  <v-container>
    <!-- MAPA -->
    <v-row v-if="type == 'map'" class="d-flex align-items-center justify-content-center">
      <v-col cols="12" lg="6" md="8" sm="12">
        <p class="text-medium text-center my-4">Proximamente</p>
      </v-col>
    </v-row>
    <!-- ACTIVIDADES -->
    <v-row v-if="type == 'activities'" class="d-flex justify-content-center">
      <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        v-for="(item, idx) in section"
        :key="item.IDEVENTO + idx"
      >
        <v-card elevation="0">
          <v-img
            height="400"
            :src="loadImg(item.URLFOTO)"
            contain
            class="grey darken-4"
          ></v-img>
          <v-card-text>
            <p v-html="item.DESCRIPCIONLARGA" class="readable"></p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- LISTA LINKEABLES -->
    <div v-if="type == 'list' && section">
      <v-row class="title-sticky">
        <v-col cols="12" lg="12" md="12" sm="12">
          <TitleSection
            v-if="currentSection"
            :title="currentSection.DESCRIPCION"
            :btnBack="true"
            class="pl-0"
          ></TitleSection>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="4"
          md="6"
          sm="12"
          v-for="(item, idx) in section"
          :key="item.IDPIN + idx"
        >
          <CardAbout
            class="readable"
            :section="item"
            :href="{ name: 'DataPinPage', params: { pin: item.IDPIN } }"
          ></CardAbout>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CardAbout from "./components/CardAbout.vue";
import { imgService } from "@/libs/media";
import TitleSection from "@/components/titles/TitleSection";
export default {
  components: {
    CardAbout,
    TitleSection,
  },
  data() {
    return {
      type: "list",
      section: null,
    };
  },
  watch: {
    section() {
      this.accTools.restart(this.lang)
    }
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      sections: "getAboutSections",
      accTools: "getAccesibilityTool",
    }),
    currentSection() {
      if (!this.sections || this.sections.length == 0) return null;
      const { section } = this.$route.params;
      if (!section) return null;
      return this.sections.find((item) => item.IDCOLECCION == section);
    },
  },
  watch: {
    lang(oldval) {
      this.loadData();
    },
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const { section } = this.$route.params;
      if (!section) return;
      this.$store.commit("activeLoader", true);
      if (section == "mapa") {
        this.type = "map";
      } else if (section == "actividades") {
        this.type = "activities";
        this.section = await this.$store.dispatch("fetchEventsList", 1);
      } else {
        this.type = "list";
        if (!this.sections || this.sections.length == 0) {
          await this.$store.dispatch("fetchAboutUsSections");
        }
        this.section = await this.$store.dispatch(
          "fetchConsultCollections",
          section
        );
      }
      this.$store.commit("activeLoader", false);
    },
    loadImg(file) {
      return imgService(file);
    },
  },
};
</script>

<style>
</style>